<template>
    <textarea v-show="false" id="smartEditor" name="smartEditor" rows="20" cols="100" />
</template>
<script>
import { onMounted, reactive, watch } from '@vue/runtime-core';
export default {
    props: [

        // 읽기전용 여부
        'isReadOnly',
        
        // 에디터 컨텐츠
        'contents',

        // 서브 디렉토리 경로
        'subUrl',

        // 내용 초기화 여부
        'isClear'
    ],
    setup(props) {

        // state
        const state = reactive({

            // editors
            oEditors: [],

            // editor instance
            smartEditorInstance: null,
        });

        /**
         * 작성한 내용 반환
         */
        const getContents = () => {

            if (!state.oEditors.length) return null;

            // 에디터로 작성된 내용 textarea의 값으로 업데이트
            state.smartEditorInstance .exec('UPDATE_CONTENTS_FIELD', []);
            
            const contents = document.getElementById('smartEditor').value;

            // "<p>&nbsp;</p>" 제거 후 반환
            return contents.replace('<p>&nbsp;</p>', '');
        }

        /**
         * 내용 설정
         */
        const setContents = (contents) => !!state.smartEditorInstance && state.smartEditorInstance.exec('PASTE_HTML', [ contents ]);

        const setClear = () => !!state.smartEditorInstance && state.smartEditorInstance.exec('SET_IR', [""]);
        
        onMounted(() => {
            
            // 가이드: http://naver.github.io/smarteditor2/user_guide/
            // 라이브러리 GIT URl: https://github.com/naver/smarteditor2/releases/tag/v2.8.2.3
            nhn.husky.EZCreator.createInIFrame({
                
                // editor instance (필수 값)
                oAppRef: state.oEditors,

                // textarea ID (필수 값)
                elPlaceHolder: 'smartEditor',

                // editor skin file Url (필수값)
                sSkinURI: '/SmartEditor2/SmartEditor2Skin.html',

                // editro 옵션 설정
                htParams : {

                    // 툴바 사용 여부 (true:사용/ false:사용하지 않음)
                    bUseToolbar : !props.isReadOnly,

                    // 입력창 크기 조절바 사용 여부 (true:사용/ false:사용하지 않음)
                    bUseVerticalResizer : false,

                    // 모드 탭(Editor | HTML | TEXT) 사용 여부 (true:사용/ false:사용하지 않음)
                    bUseModeChanger : !props.isReadOnly,
                },

                // fCreator 설정 (필수 값)
                fCreator: 'createSEditor2',

                // 에디터 로드 완료 후 호출되는 콜백 함수
                fOnAppLoad: () => {

                    state.smartEditorInstance = state.oEditors.getById['smartEditor'];

                    // 초기화 여부
			        (!!props.isClear && props.isClear === 'Y') && setClear();

                    // 기존 컨텐츠가 존재하면 설정
			        !!props.contents && setContents(props.contents);                    

                    // 이미지 서브 디렉토리 경로가 존재하면 세션에 저장
                    !!props.subUrl && sessionStorage.setItem('photoSubDirectory', props.subUrl);

                    // 읽기전용일 경우 설정
                    if (props.isReadOnly) {

                        state.smartEditorInstance .exec("DISABLE_WYSIWYG");
                        state.smartEditorInstance .exec("DISABLE_ALL_UI");
                    }
                }
            });
        });

        watch(() => props.contents, () => {

            // 기존 컨텐츠가 존재하면 설정
            if(!!props.contents && state.oEditors.length){
                if(!!props.isClear && props.isClear === 'Y'){
                    setClear()
                }

                setContents(props.contents)
            }
            else{
                
                setClear()
                setContents(props.contents)
            }
			// (!!props.contents && state.oEditors.length) && setContents(props.contents);
        });

        watch(() => props.isClear, () => {

            // 기존 컨텐츠가 존재하면 설정
			(!!props.isClear && props.isClear === 'Y') && setClear() && setContents(props.contents);
        });

        return {
            state,
            setClear,
            getContents,
            setContents,
        }
    },
}
</script>