import { FoxBizRequest, FoxRestBizClient } from "@/api/client";
import moment from "moment/moment";

class CimsNoticeDetail {
    
    /**
     * 생성자
     */
     constructor() {
        this.systemCode = 'CIM';
        this.boardCategory = 'CIMCM002';
    }

    /**
     * cims 공지사항 상세 조회
     * @param {Any} parameters 
     * @returns 
     */
    getKRTBoardContent(parameters) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Koreit.Biz.KRTBoardBiz';
            request.methodId = 'GetKRTBoardContent';
            request.parameters.dic = parameters

            service.execute(request).then(response => {

                const tables = response.data.result.value;
                
                // Table rows 데이터 가공
                tables.Table.rows.forEach(row => {

                    // 날짜 포맷 변경
                    if (row.StartpiredDt) row.StartpiredDt = moment(row.StartpiredDt).format('YYYY-MM-DD');
                    if (row.ExpiredDt) row.ExpiredDt = moment(row.ExpiredDt).format('YYYY-MM-DD');
                    if (row.StartAnnounceDt) row.StartAnnounceDt = moment(row.StartAnnounceDt).format('YYYY-MM-DD');
                    if (row.AnnounceDt) row.AnnounceDt = moment(row.AnnounceDt).format('YYYY-MM-DD');
                });

                if(tables) resolve(tables);
                else resolve(null);
                
            }).catch(error => reject(error));
        });
    }

    /**
     * cims 공지사항 상세 저장
     * @param {Any} detail 
     * @param {String} file 
     * @returns 
     */
    saveBoardContent(detail, file) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardBiz'
            request.methodId = 'SaveBoardContent'
            request.parameters.dic = detail
            request.parameters.file = file

            service.execute(request).then(response => {

                resolve(response.data)

            }).catch(error => reject(error));
        });
    }

    /**
     * cims 공지사항 상세 삭제
     * @param {Any} detail 
     * @returns 
     */
     deleteBoardContent(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Common.Biz.BoardBiz'
            request.methodId = 'DeleteBoardContent'
            request.parameters.dic = detail

            service.execute(request).then(response => {

                resolve(response.data)
                
            }).catch(error => reject(error));
        });
    }
}
export const cimsNoticeDetail = new CimsNoticeDetail();