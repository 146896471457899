<template>
    <div>
        <div class="file-wrap">
            <input type="file" ref="fileInput" style="display: none;" multiple @change="eventHandler.onAddFileChange" />
            <!-- [check-this] 상세 mode - button visible handle .,. + with 삭제 버튼 -->
            <button type="button" title="추가" class="btn-box icon icon-plus" autofocus
                @click="eventHandler.onFileAddButtonClick" v-if="state.isBtnShow">추가</button>
        </div>
        <ul class="file-list mt10 a-hover">
            <li v-for="file of state.attachments" :key="file">
                <p @click="eventHandler.downloadFile(file)"><a href="javascript:;">{{ file.FileNm }} - {{ file.FileSize }}</a></p>
                <button type="button" title="삭제" class="remove_btn"
                    @click="eventHandler.onRemoveFileButtonClick(file, false)" v-if="state.isBtnShow"><span
                        class="text_hidden">삭제</span></button>
            </li>
            <li v-for="file of state.addedFiles" :key="file">
                <p><a href="javascript:;">{{ file.name }} - {{ file.size }}</a></p>
                <button type="button" title="삭제" class="remove_btn"
                    @click="eventHandler.onRemoveFileButtonClick(file, true)" v-if="state.isBtnShow"><span
                        class="text_hidden">삭제</span></button>
            </li>
        </ul>
    </div>
</template>
<script>
import axios from 'axios';
import alertConfirmManager from '@/utils/alert-confirm-manager';
import { reactive, ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { watch } from '@vue/runtime-core';

/**
 * 파일 업로더 컴포넌트 (공통)
 */
export default {
    props: [
        // 서브 디렉토리 경로
        'subUrl',
        // 기존 첨부파일 목록
        'attachments',
        // 첨부할 수 있는 파일 최대 갯수
        'maxFileCount',
        // 추가, 삭제 버튼 노출 여부
        'isBtnShow'
    ],
    setup(props, { emit }) {
        // 스토어
        const store = useStore();

        // state (변수)
        const state = reactive({
            // 첨부파일 목록 (기존에 첨부되어있는)
            attachments: !!props.attachments ? props.attachments : [],
            // 추가한 파일 목록
            addedFiles: [],
            // 추가가능한 최대 파일 수
            maxFileCount: !!props.maxFileCount ? props.maxFileCount : 9999,
            // [check-this] .,. FileUploader 사용하는 기존 UI 영향도 .,. 없도록 ...
            isBtnShow: props.isBtnShow != void 0 ? props.isBtnShow : true
        });

        // 파일 input ref
        const fileInput = ref(null);

        // 이벤트 핸들러
        const eventHandler = {
            /**
             * 파일추가 버튼 클릭 이벤트 처리
             */
            onFileAddButtonClick: () => {
                const attachmentsCount = state.addedFiles.length + state.attachments.length;

                // 첨부파일 limit 체크
                if (state.maxFileCount <= attachmentsCount) {

                    alertConfirmManager.alert.warning(`파일첨부는 ${state.maxFileCount}개 까지 업로드 가능합니다.`);
                } else {
                    fileInput.value.click();
                }
            },

            /**
             * 추가파일 변경 이벤트 처리
             */
            onAddFileChange: () => {

                const files = [...fileInput.value.files];
                const attachmentsCount = state.addedFiles.length + state.attachments.length + files.length;

                // 첨부파일 limit 체크
                if (state.maxFileCount < attachmentsCount) {

                    alertConfirmManager.alert.warning(`파일첨부는 ${state.maxFileCount}개 까지 업로드 가능합니다.`);
                } else {

                    // 선택한 파일 담기 (중복은 제거)
                    files.forEach(file => {

                        const isExist = state.addedFiles.some(addedFile => addedFile.name === file.name);
                        !isExist && state.addedFiles.push(file);
                    });
                }

                // file input 값 비움
                fileInput.value.value = null;
            },

            /**
             * 파일삭제 버튼 클릭 이벤트 처리
             * @param {any} file : 파일정보
             * @param {boolean} isNew : 신규파일여부
             */
            onRemoveFileButtonClick: (file, isNew) => {
                if (isNew) {
                    state.addedFiles = state.addedFiles.filter(addedFile => addedFile.name !== file.name);
                } else {
                    // 기존파일 삭제 시 확인후 진행
                    alertConfirmManager.confirm.remove().then(() => {
                        // [check-this] [HPSTDB].[dbo].[TB_CM_FILE_INFO] : SystCode, FileSeq PK .. 
                        // [check-this] 우선 첨부파일 테이블이 위 1 개로 판단함.
                        // [check-this] 만약... 동일 component 사용고, 첨부파일 테이블이 다르고, PK 다르다면...
                        // [check-this] 아래 where 절... 고민할 필요가 있음...
                        var trgtIdx = state.attachments.findIndex((x) => {
                            return x.SystCode === file.SystCode && x.FileSeq === file.FileSeq;
                        });

                        state.attachments.splice(trgtIdx, 1);

                        //삭제된 값 부모컴포넌트에 보내기
                        emit('attachments', state.attachments);
                        
                        // [check-this] filter miss...
                        //state.attachments = state.attachments.filter(attachment => attachment.name !== file.name);
                    });
                }
            },

            /**
             * 파일 다운로드 실행
             * @param {any} file : 파일정보
             */
            downloadFile: file => {
                // 진행 토스트
                alertConfirmManager.toast.fileDownloading();

                const url = process.env.VUE_APP_FILE_UPDOWNLOAD_HANDLER_BASE_DIR + store.getters.fileDownloadUrl;
                const formData = new FormData();

                formData.append("VIRDIR", file.FilePath);

                axios.post(url, formData, {
                    responseType: 'blob',
                }).then(response => {

                    // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환
                    const blob = new Blob([response.data]);

                    // blob을 사용해 객체 URL을 생성
                    const fileUrl = window.URL.createObjectURL(blob);

                    // 다운로드 파일명
                    const fileName = response.headers["content-disposition"].split("filename=")[1].replace(/"/g, "");

                    // blob 객체 URL을 설정할 링크 생성
                    const link = document.createElement('a');
                    link.href = fileUrl;
                    link.style.display = 'none';
                    link.download = decodeURIComponent(fileName);

                    // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행
                    document.body.appendChild(link);
                    link.click();
                    link.remove();

                    // 다운로드가 끝난 리소스(객체 URL)를 해제
                    window.URL.revokeObjectURL(fileUrl);

                    // 완료 토스트
                    alertConfirmManager.toast.fileDownloaded();
                });
            },
        }

        /**
         * 파일 업로드 실행
         * @returns : 업로드 파일 정보
         */
        const uploadFiles = () => {

            return new Promise((resolve, reject) => {
                
                const url = process.env.VUE_APP_FILE_UPDOWNLOAD_HANDLER_BASE_DIR + store.getters.fileUploadUrl;
                const formData = new FormData();

                state.addedFiles.forEach(file => formData.append("files", file));

                formData.append("SUBURL", props.subUrl);
                formData.append("SYSTEM_CODE", "ADM");

                axios.post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'AUTHINFO': sessionStorage.getItem('FoxRest-Authenticate'),
                    }
                }).then(response => {

                    // 선택한 파일목록 초기화
                    state.addedFiles = [];

                    const files = response.data.files;

                    // file size 변환
                    files.forEach(file => {

                        if (file.size > 1024) {

                            let kb = (file.size / 1024).toFixed(2);
                            let mb = (kb / 1024).toFixed(2);
                            let gb = (mb / 1024).toFixed(2);

                            // byte file size 변경 (적당한 단위로)
                            const resultFileSize = kb < 1024 ? `${kb} KB` : mb < 1024 ? `${mb} MB` : `${gb} GB`;

                            // file size 재설정
                            file.size = resultFileSize;
                        }
                        else {

                            // file size 재설정
                            file.size = `${file.size} Byte`;
                        }
                    });

                    // 결과 반환
                    resolve(response);
                }).catch(error => reject(error));
            });
        }
        // [check-this] max count us database value... 
        watch(() => props.maxFileCount, () => {
            state.maxFileCount = props.maxFileCount
        });

        // 기존 첨부파일 변경 감시자
        watch(() => props.attachments, () => {
            // 기존 첨부파일 설정
            state.attachments = !!props.attachments ? props.attachments : [];
        });

        return {
            store,
            state,
            props,
            fileInput,
            eventHandler,
            uploadFiles,
        }
    }
}
</script>