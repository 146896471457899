<template>
    <div class="admin-content">
        <h3>게시판 글쓰기</h3>
        <div class="flex-wrap right">
            <div>
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getCimsNoticeList">목록</button>
                    <button class="btn-box basic" @click="methods.moveScreen">취소</button>
                    <button class="btn-box purple" @click="methods.saveCimsNoticeDetail">저장</button>
                    <button class="btn-box red" v-if="state.dataTable.isEdit" label="삭제" @click="methods.deleteCimsNoticeDetail">삭제</button>
                </div>
            </div>
        </div>

        <!-- 상세 내용 -->
		<div class="table-wrap table-list none-scroll-y mt50">
			<div class="table-box">
				<table>
					<colgroup>
						<col width="15%">
						<col width="85%">
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">제목 <i class="required">*</i></th>
							<td><input class="input-text" id="title" type="text" ref="title" v-model.trim="state.dataTable.cimsNoticeItems.Title" maxlength="128" /></td>
						</tr>
						<tr>
							<th scope="row">게시기간</th>
							<td>
								<div class="period-wrap">
									<div>
                                        <input type="date" class="input-text" v-model="state.dataTable.cimsNoticeItems.StartpiredDt" :showIcon="true" placeholder="시작일"/>
                                    </div>
									<span>~</span>
									<div>
                                        <input type="date" class="input-text" v-model="state.dataTable.cimsNoticeItems.ExpiredDt" :showIcon="true" placeholder="종료일" :disabled="!state.dataTable.isEverasting"/>
                                    </div>
									<div class="chk-wrap">
										<div class="chk-box">
											<input type="checkbox" v-model="state.dataTable.chkEverlasting" :binary="true" @change="eventHandler.changeEverlasting"/>
											<label><p>영구게시</p></label>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<th scope="row">상단글</th>
							<td>
								<div class="period-wrap">
									<div>
                                        <input type="date" class="input-text" v-model="state.dataTable.cimsNoticeItems.StartAnnounceDt" :showIcon="true" placeholder="시작일" :disabled="!state.dataTable.isFixed"/>
                                    </div>
									<span>~</span>
									<div>
                                        <input type="date" class="input-text" v-model="state.dataTable.cimsNoticeItems.AnnounceDt" :showIcon="true" placeholder="종료일" :disabled="!state.dataTable.isFixed"/>
                                    </div>
									<div class="chk-wrap">
										<div class="chk-box">
											<input type="checkbox" v-model="state.dataTable.chkFixed" :binary="true" @change="eventHandler.changeFixed"/>
											<label><p>상단글 고정</p></label>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<th scope="row">내용 <i class="required">*</i></th>
							<td>
                                <SmartEditor ref="smartEditor" :contents="state.Content" />
							</td>
						</tr>
						<tr>
							<th scope="row">파일첨부</th>
						    <td>
                                <FileUploader ref="fileUploader" :subUrl="store.getters.boardSubDirectoryPath" :attachments="state.dataTable.fileItems" :maxFileCount="1"/> 
						    </td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- //상세 내용 -->
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { cimsNoticeDetail } from '@/modules/cims/CIM_CM0121D.js';
import { useRoute } from 'vue-router';
import router from '@/router'
import alertManager from '@/utils/alert-confirm-manager';
import FileUploader from '@/components/FileUploader.vue';
import SmartEditor from '@/components/SmartEditor.vue';
export default {
    name: 'CIM_CM0121D',
    components: { FileUploader, SmartEditor },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const title = ref(); // 제목

        // 파일 업로더
        const fileUploader = ref(null);

        // 스마트 에디터
        const smartEditor = ref(null);

        // 라우트
        const vrt = useRoute();

        // state
        const state = reactive({
            parameter: {
                // 시퀀스
                Seq: vrt.query.Seq === undefined ? null : vrt.query.Seq,

                // 시스템코드
                SystCode: cimsNoticeDetail.systemCode,

                // 보드 카테고리
                BoardCategory: cimsNoticeDetail.boardCategory,

                // 그룹번호
                GroupNo: 0,
            },
            dataTable: {
                // 공지사항 상세
                cimsNoticeItems: new Object(),

                // 파일 정보
                fileItems: new Array(),

                // 영구게시 체크
                chkEverlasting : true,

                // 게시기간 종료일 활성화/비활성화
                isEverasting : false,

                // 상단글 고정 체크
                chkFixed : false,

                // 상단글 시작일/종료일 활성화/비활성화
                isFixed : false,

                // 수정여부
                isEdit: vrt.query.Seq === undefined ? false : true,
            },
            Content:'',
        });

        // 메서드
        const methods = {

            // cims 공지사항 상세 조회
            getCimsNoticeDetail: () => {
                
                cimsNoticeDetail.getKRTBoardContent(JSON.stringify(state.parameter)).then(result => {
                    if (!!result.Table.rows.length) {
                        
                        state.dataTable.cimsNoticeItems = result.Table.rows[0];

                        // 상단글 고정 체크여부 바인딩
                        state.dataTable.chkFixed = state.dataTable.cimsNoticeItems.IsAnnounce == 'Y' ? true : false;

                        // 상단글 시작일/종료일 활성화/비활성화
                        state.dataTable.isFixed = state.dataTable.chkFixed;

                        // 영구게시 체크여부 바인딩
                        state.dataTable.chkEverlasting = state.dataTable.cimsNoticeItems.ExpiredDt == null ? true : false;

                        state.dataTable.isEverasting = !state.dataTable.chkEverlasting;
                    }
                    else {
                        state.dataTable.cimsNoticeItems.SystCode = cimsNoticeDetail.systemCode;
                        state.dataTable.cimsNoticeItems.BoardCategory = cimsNoticeDetail.boardCategory;
                        state.dataTable.cimsNoticeItems.UseYn = "Y";
                        state.dataTable.cimsNoticeItems.GroupNo = 0;
                        state.dataTable.cimsNoticeItems.DepthNo = 1;
                        state.dataTable.cimsNoticeItems.ParentNo = 0;
                    }
                    state.dataTable.fileItems = result.Table1.rows;
                    state.Content = state.dataTable.cimsNoticeItems.Content;
                });

            },

            // 목록으로 이동
            getCimsNoticeList: () => {

                router.push({ path: '/CIM_CM0120R' });
            },

            // 상세화면/목록으로 이동
            moveScreen: () => {

                if (vrt.query.Post === "N") {
                    router.push({ path: '/CIM_CM0120R' });
                }
                else if (vrt.query.Post === "U") {
                    var q = vrt.query;
                    q["Seq"] = vrt.query.Seq;
                    router.push({ name: 'CIM_CM0121D', query: q });
                }
            },
            
            // cims 공지 상세 저장
            saveCimsNoticeDetail: () => {
                
                // 스마트 에디터 값 넣기
                state.dataTable.cimsNoticeItems.Content = smartEditor.value.getContents();

                // 이메일 사용 안하는 것으로 확인  AS-IS 반영
                state.dataTable.cimsNoticeItems.Email = '';

                if (methods.noticeValidation()) {
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
    
    
                    fileUploader.value.uploadFiles().then(response => {

                        let fileArr = null;
                        if (response.data.files.length) {
    
                            fileArr = {
                                "FileSeq": "",
                                "FileNm": response.data.files[0]["name"],
                                "FilePath": response.data.files[0]["filePath"],
                                "FileSize": response.data.files[0]["size"],
                                "FileType": response.data.files[0]["mimeType"],
                                "IsContent": "N",
                                "IsThumb": "N",
                                "UseYn": "Y"
                            }
                        }
                         else {

                            if (!state.dataTable.fileItems.length) fileArr = {};
                        }
                        fileArr = !!fileArr ? JSON.stringify(fileArr) : null;

                        cimsNoticeDetail.saveBoardContent(JSON.stringify(state.dataTable.cimsNoticeItems), fileArr).then((response) => {
    
                            if (response.success) {
    
                                alertManager.toast.save();
                            }
    
                        }).finally(() => {

                            // 목록 재조회
                            methods.getCimsNoticeList();

                            // 스토어 값 변경
                            store.commit("setLoadingStatus", false);
    
                        });
                        
                    }).catch(error => {
    
                        console.log(error)
    
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                }
                
            },

            // cims 공지 상세 삭제
            deleteCimsNoticeDetail: () => {

                alertManager.confirm.remove().then(() => {
                    store.commit("setLoadingStatus", true);

                    cimsNoticeDetail.deleteBoardContent(JSON.stringify(state.dataTable.cimsNoticeItems)).then().then((response) => {

                        if (response.success) {

                            alertManager.toast.remove();
                        }

                    }).finally(() => {

                        // 목록 재조회
                        methods.getCimsNoticeList();

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                });
            },

            // 유효성 검사
            noticeValidation: () => {

                let validator = state.dataTable.cimsNoticeItems;
                
                //제목
                if (!validator.Title) {

                    title.value.focus();
                    alertManager.alert.warning("제목을 입력해주세요.");
                    return false;
                }
                //내용
                else if (!validator.Content) {
                    
                    alertManager.alert.warning("내용을 입력해주세요.");
                    return false;
                }
                else {
                    return true;
                }
            },

        }

        // 이벤트 핸들러
        const eventHandler = {

            // 영구게시 이벤트
            changeEverlasting : () => {

                if(state.dataTable.chkEverlasting) {
                    state.dataTable.cimsNoticeItems.ExpiredDt = null;
                    state.dataTable.isEverasting = false;
                }
                else {
                    state.dataTable.isEverasting = true;
                }
            },

            // 상단글 고정 이벤트
            changeFixed : () => {
                if(state.dataTable.chkFixed) {
                    state.dataTable.cimsNoticeItems.IsAnnounce = "Y";
                    state.dataTable.isFixed = true;
                }
                else {
                    state.dataTable.cimsNoticeItems.StartAnnounceDt = null;
                    state.dataTable.cimsNoticeItems.AnnounceDt = null;
                    state.dataTable.cimsNoticeItems.IsAnnounce = "N";
                    state.dataTable.isFixed = false;
                }
            }
        }

        onMounted(() => {

            // cims 공지사항 상세 최초조회
            methods.getCimsNoticeDetail();
        });

        return {
            state,
            cimsNoticeDetail,
            store,
            methods,
            eventHandler,
            fileUploader,
            smartEditor,
            title,
        }
    }
}
</script>